body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background: #fff;
  color: #041e37;
  overflow-x: hidden;
}

a {
  color: #041e37;
  text-decoration: none;
}

a:hover {
  color: #041e37;
  text-decoration: none;
}

.h2tag {
  color: #041e37;
  font-size: 36px;
  font-weight: 600;
}
.navbar-toggler-icon {
  filter: invert(1);
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #041e37;
}

img.brand_logo {
  width: 150px;
}

.main_navbar {
  z-index: 10;
  background: #f6ecc3;
}
.main_navbar .dropdown-menu {
  background: #041e37;
  box-shadow: 0 0 10px 0 #00000080;

  width: 100%;
}
.main_navbar .dropdown-toggle::after {
  vertical-align: 0.155em;
  margin-left: 0.555em;
}
.main_navbar .dropdown-toggle.show {
  color: #fff;
  outline: 0;
}
.main_navbar .dropdown-item:hover {
  background: #0000004f !important;
  color: #fff !important;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #ece0c0, #fff);
  background: #f6ecc3;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.offcanvas {
  background: #041e37;
}

.navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}

.offcanvas-header .btn-close {
  filter: invert(1);
}

.navbar_right .nav-item + li {
  margin-left: 30px;
}

.navbar_right li a {
  color: #ecf1f5;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.navbar_right li a:hover,
.navbar_right li a.active {
  color: #e9d16f;
}

.navbar_right li a svg {
  margin-right: 12px;
}
.navbar_right .navbar-nav {
  align-items: center;
}
.page_header {
  background: radial-gradient(
    48.75% 28.45% at 27.46% 47.47%,
    #003d78 0%,
    #041e37 100%
  );
  padding: 150px 0;
  margin-top: -110px;
  position: relative;
  z-index: 1;
}

.page_header::before {
  content: "";
  background: url("./assets/images/banner_bg.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.page_header h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  background: linear-gradient(180deg, #fff 0%, #e9d16f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page_header h5 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 30px;
}

.banner_btn_grp {
  display: flex;
  gap: 30px;
}

.primary_btn {
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #00519f !important;
  padding: 12px 30px;
  background: linear-gradient(90deg, #041e37 0%, #00519f 100%);
  position: relative;
  z-index: 1;
}
.primary_btn:hover {
  background: linear-gradient(270deg, #d6b52e 40%, #d6b52e 40%);
  border: 1px solid #988538 !important;
  color: #000 !important;
}
.primary_btn:hover .primary_btn_arrow {
  background: #fcf3d1;
}
.primary_btn::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 50px;
  background: linear-gradient(
    90deg,
    #041e37 0%,
    rgba(4, 30, 55, 0) 57.81%,
    #00519f 100%
  );
  z-index: -1;
}
.primary_btn:hover::before {
  background: linear-gradient(
    90deg,
    #fffca6 0%,
    rgba(4, 30, 55, 0) 57.81%,
    #c3ae40 100%
  );
}
.primary_btn_flx {
  display: flex;
  align-items: center;
  padding: 8px 15px;
}

.primary_btn_arrow {
  width: 31px;
  height: 31px;
  background: #d9d9d9;
  filter: drop-shadow(0px 0px 17px #e9d16f);
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.banner_info_box_section {
  margin-top: -90px;
  z-index: 1;
  position: relative;
}

.banner_info_box {
  display: flex;
  justify-content: space-around;
  border-radius: 20px;
  border: 1px solid rgba(233, 209, 111, 0.42);
  background: linear-gradient(180deg, #ffd52c 0%, #fff 100%);
  box-shadow: 0px 15px 35px -6px rgba(233, 209, 111, 0.42);
  padding: 30px;
}

.banner_info_box_single {
  display: flex;
  align-items: flex-start;
}

.banner_info_box_single > div {
  margin-left: 10px;
}

.banner_info_box_single > div h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 8px;
}

.banner_info_box_single > div h3 {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.section {
  padding: 80px 0;
}

.main_title {
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  text-transform: capitalize;
}

.main_title span {
  display: block;
}

.main_para {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.custom_list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
}

.custom_list li {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.custom_list li::before {
  content: "";
  background: #d6b52e;
  width: 12px;
  height: 12px;
  position: absolute;
  transform: rotate(45deg);
  top: 6px;
  left: -22px;
}

.feature_section {
  background: rgba(233, 209, 111, 0.42) url("./assets/images/features_bg.png")
    no-repeat center;
  background-size: cover;
}

.features_box {
  border-radius: 20px;
  border: 1px solid #ffe98f;
  background: #fff;
  padding: 50px;
  transition: 0.2s all;
  cursor: default;
  height: 100%;
}

.features_box:hover {
  box-shadow: 0px 0px 28px 2px #d6b52e;
}

.features_box h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.feature_section_row [class*="col-"] {
  margin-bottom: 30px;
}

.feature_section_row {
  margin-top: 30px;
}

.activity_section {
  position: relative;
  z-index: 1;
}

.activity_section::before {
  content: "";
  background: url("./assets/images/recent_activity_before.png") left center
    no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.activity_section::after {
  content: "";
  background: url("./assets/images/recent_activity_after.png") right center
    no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.activity_panel {
  margin-top: 50px;
}

.activity_row {
  border-radius: 10px;
  border: 1px solid #e9d16f;
  background: #fff;
  transition: 0.2s all;
  padding: 5px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.activity_row:hover {
  background: #e9d16f;
}

.activity_row p {
  margin-bottom: 0;
}

.activity_row_left,
.activity_row_right {
  display: flex;
  align-items: center;
  gap: 15px;
}

p.activity_txt {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

p.activity_id {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 17px -2px #e9d16f;
  padding: 8px 22px;
}

.bg_dark {
  background: #041e37 url("./assets/images/banner_bg.png") no-repeat center;
  background-size: cover;
}

.bg_dark .main_title,
.bg_dark .main_para {
  color: #fff;
}

.plan_detail_box {
  border-radius: 20px;
  border: 1px solid #fff;
  padding: 30px;
  max-width: 310px;
  margin: 0 auto;
}

.plan_detail_box_top {
  border-bottom: 1px solid #ecf1f5;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.plan_detail_box_top > div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.plan_detail_box_top > div h2 {
  color: #e9d16f;
  font-size: 48px;
  font-weight: 700;
  line-height: normal;
}

.plan_detail_box_top > div h4 {
  color: #e9d16f;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  white-space: normal;
  word-break: break-word;
  max-width: 120px;
}

.plan_detail_box p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #ecf1f5;
  margin-bottom: 0;
}

.plan_detail_box_bottom h2 {
  color: #e9d16f;
  font-size: 48px;
  font-weight: 600;
  line-height: normal;
}

#faq_accordian {
  margin-top: 50px;
}

.faq .accordion-item {
  border: 0 !important;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 17px -2px rgb(233 209 111 / 80%);
}

.faq .accordion-collapse {
  transition: 0.5s;
}

.faq .accordion-button {
  box-shadow: none !important;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  transition: 0.5s;
  color: #041e37;
}

.accordion-button::after {
  transform: rotate(-90deg);
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0);
  filter: brightness(0);
}

.faq .accordion-body p {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

footer.footer {
  background: #e9d16f;
}

.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}

.footer_panel_left p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0;
}

.footer_panel_left p a {
  color: #041e37;
  display: block;
  white-space: normal;
  word-break: break-all;
}

.footer_menu_links {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer_menu_links li a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}

.footer_social_links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer_social_links li a {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: #041e37;
  color: #e9d16f;
  border-radius: 50%;
  text-decoration: none;
  transition: 0.2s all;
}

.footer_social_links li a:hover {
  background: #d6b52e;
  color: #041e37;
}

.dashboard {
  background: linear-gradient(
    180deg,
    #041e37 21.99%,
    #ffe98f 42.73%,
    #fff 86.54%
  );
  padding: 150px 0;
  margin-top: -110px;
}

.dashboard_box {
  background: linear-gradient(180deg, #fff9de 0%, #fff 100%);
  padding: 30px 40px;
  border-radius: 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.dashboard_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/dash_bg.png") no-repeat center;
  /* background-attachment: fixed ; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.dashboard .head_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.dashboard .head_flx .wallet {
  border-radius: 28px;
  background: #fff;
  box-shadow: 0px 0px 17px -2px #e9d16f;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #626262;
}

.dashboard .head_flx .wallet img {
  margin-top: -2px;
  cursor: pointer;
}
.dashboard .head_flx .wallet img:hover,
.dashboard .box .link .copy:hover {
  filter: invert(20%) sepia(1%) saturate(5702%) hue-rotate(172deg)
    brightness(59%) contrast(190%);
}
.dashboard .box {
  border-radius: 20px;
  border: 1px solid #fff7d8;
  background: #fff8dc;
  box-shadow: -10px 10px 35px -6px #d6b52e, -4px 9px 24px -5px #d6b52e inset;
  padding: 25px 25px 25px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
}
.tooltipCard h4 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 7px;
}
.tooltipCard ul li {
  font-size: 15px !important;
}
.react-tooltip {
  z-index: 9;
  border-radius: 20px !important;
  border: 1px solid #fff7d8 !important;
  background: #fff8dc !important;
  box-shadow: -10px 10px 35px -6px #d6b52e, -4px 9px 24px -5px #d6b52e inset;
  padding: 25px 25px 25px 30px;
  color: #041e37 !important;
}
.react-tooltip ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.react-tooltip ul li {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
}
.react-tooltip ul li label {
  margin-right: 0;
  min-width: 150px;
  color: #626262;
}
.react-tooltip ul li span {
  position: static !important;
  color: #041e37 !important;
  font-weight: 500;
}
.dashboard .box .icon {
  border-radius: 10px;
  background: #f1ce40;
  box-shadow: 0px 0px 17px -2px #e9d16f;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard .box h5 {
  color: #041e37;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10p;
}

.dashboard .box p {
  color: #1e1e1e;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}

.dashboard .box h6 {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.dashboard .box_bdr {
  border-radius: 40px;
  border: 1px solid #d6b52e;
  padding: 27px;
  margin-bottom: 24px;
}

.dashboard .box_bdr .box {
  padding: 25px;
  justify-content: center;
}

.dashboard .box_bdr .box p {
  margin-bottom: 10px;
}

.dashboard .box_bdr .box h5 {
  margin-bottom: 0;
}

.dashboard .box .link {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 17px -2px #e9d16f;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.dashboard .box .link .half {
  width: 83%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.dashboard .box .link .copy {
  cursor: pointer;
}

.dashboard .box ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard .box ul a {
  background: #cacaca;
  color: #fff8dc !important;
  text-decoration: none !important;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 8px;
  transition: 0.4s;
}

.dashboard .box ul a:hover {
  background: #d6b52e;
}

.dashboard .progress {
  border-radius: 20px;
  background: #cacaca;
  width: 92% !important;
}
.defaultIds {
  padding: 4px 14px;
  background: #173142;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
}

.dashboard .progress-bar {
  background-color: #f1ce40;
}

.dashboard .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    #ffffff26 25%,
    #ffe474 25%,
    #ffe474 50%,
    #ffffff26 50%,
    rgba(255, 255, 255, 0.15) 75%,
    #ffe474 75%,
    #ffe474
  );
}

.dashboard .box .flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard .box .flx span {
  color: #1e1e1e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
}

.dashboard .box .flx .bold {
  font-weight: 600;
}

.dashboard .account {
  margin-right: 40px;
}

.totalbox {
  padding-bottom: 35px !important;
}

.dash_btns {
  justify-content: center;
  margin-top: -50px;
}
.dashboard .box_flx {
  display: flex;
  align-items: flex-start;
}
/* .dashboard .box_flx:nth-child(1){
  margin-bottom: -70px;
} */
.dashboard .box_flx .input-group {
  max-width: 45%;
  margin: 0 auto;
}
.dashboard .box_flx .input-group-text {
  background: white;
  border: 0;
  border-radius: 0 30px 30px 0;
  box-shadow: 0px 0px 17px -2px #e9d16f;
}
.dashboard .box_flx .box {
  width: fit-content;
  text-align: center;
}
.dashboard .box_flx input {
  border-radius: 28px;
  background: #fff;
  border: 0;
  padding: 14px 20px;
  box-shadow: -10px -1px 17px -2px #e9d16f;
}
.dashboard .box_flx input::placeholder {
  color: #979595;
}
.dashboard .blw_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
}
.dashboard .blw_box > div {
  display: flex;
}
.dashboard .blw_box p {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.dashboard .blw_box h5 {
  margin-bottom: 0;
  color: #041e37;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
}
.dashboard .accordion-item {
  border-radius: 40px;
  border: 1px solid #d6b52e;
  overflow: hidden;
  padding: 0;
  margin-bottom: 24px;
  background: transparent;
}
.dashboard .accordion-button {
  border: 0;
  background: transparent;
  padding: 20px 25px;
  box-shadow: none !important;
  color: #041e37;
  font-size: 24px;
  font-weight: 600;
}
.dashboard .accordion-button.collapsed::after {
  background-image: url("./assets/images/right_arw.png");
  transform: rotate(0deg);
  background-size: contain;
  width: 12px;
  height: 20px;
  transition: 0.4s;
}
.dashboard .accordion-button::after {
  transform: rotate(90deg);
  background-image: url("./assets/images/right_arw.png");
  background-size: contain;
  width: 12px;
  height: 20px;
  transition: 0.4s;
}
.dashboard .accordion-body {
  padding: 20px 25px;
  padding-top: 40px;
}

.dashboard .table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: rgb(254 251 235);
}
.dashboard .table > :not(caption) > * > * {
  background-color: rgb(254 251 235);
}
.dashboard .table th {
  background: #f8ecbd;
  white-space: nowrap;
}

.plan_pool {
  margin: 0 auto;
}
.plan_pool img {
  z-index: 1;
}
.plan_pool_head {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: 10px auto 40px;
}

.plan_pool_head::after {
  content: "";
  background-color: #979595;
  width: 0.9px;
  height: 57%;
  position: absolute;
  bottom: -40px;
}
.plan_pool_head_child {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  margin: 29px 5px 0;
}
.plan_pool_first_row .plan_pool_head_child {
  width: 100px;
  height: 100px;
  font-size: 36px;
}
.plan_pool_first_row {
  display: flex;
  justify-content: space-around;
  position: relative;
}
.plan_pool_first_row::after {
  content: "";
  background-color: #979595;
  width: 50%;
  height: 1px;
  position: absolute;
}
.plan_pool_head_child::before {
  content: "";
  background: #979595;
  width: 1px;
  height: 75%;
  position: absolute;
  top: -29px;
}
.plan_pool_second_row .plan_pool_head_child {
  width: 85px;
  height: 85px;
  font-size: 30px;
  border-radius: 12px;
}
.plan_pool_second_row > div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
}
.plan_pool_second_row,
.plan_pool_third_row,
.plan_pool_fourth_row,
.plan_pool_fifth_row {
  display: flex;
  justify-content: space-around;
}
.plan_pool_first_row .plan_pool_head_child::after,
.plan_pool_second_row .plan_pool_head_child::after,
.plan_pool_fourth_row .plan_pool_head_child::after {
  content: "";
  background-color: #979595;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -32px;
}
.plan_pool_third_row > div,
.plan_pool_fourth_row > div,
.plan_pool_fifth_row > div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
}
.plan_pool_second_row > div::before,
.plan_pool_third_row > div::before,
.plan_pool_third_row > div::before,
.plan_pool_fourth_row > div::before,
.plan_pool_fifth_row > div::before {
  content: "";
  background-color: #979595;
  width: 50%;
  height: 1px;
  position: absolute;
}
.plan_pool_third_row .plan_pool_head_child {
  width: 65px;
  height: 65px;
  font-size: 24px;
  border-radius: 10px;
  flex-direction: column;
}
.plan_pool_fourth_row .plan_pool_head_child {
  width: 45px;
  height: 45px;
  font-size: 20px;
  border-radius: 8px;
}
.plan_pool_fifth_row .plan_pool_head_child {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  font-size: 12px;
}
.plan_pool_head_child span,
.plan_pool_head span {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  right: -75%;
}
.plan_pool_third_row .plan_pool_head_child span {
  position: unset;
  right: unset;
  margin-top: 5px;
}

.joinnow {
  padding: 200px 0;
  margin-top: -70px;
  background: url("./assets/images/joinnow_bg.png") no-repeat 0 -50px;
  background-size: 100%;
  min-height: 100vh;
}

.joinnow .joinnow_box {
  border-radius: 20px;
  border: 1px solid rgba(233, 209, 111, 0.42);
  background: linear-gradient(180deg, #ffd52c 0%, #fff 100%);
  box-shadow: 0px 15px 35px -6px rgba(233, 209, 111, 0.42),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 70px;
  position: relative;
  z-index: 1;
  margin-top: -50px;
}
.joinnow .joinnow_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/join.png") no-repeat center;
  right: 0;
  left: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}
.joinnow .form-control {
  border-radius: 28px;
  background: #fff;
  box-shadow: 0px 0px 17px -2px #e9d16f;
  border: 0;
  padding: 14px 20px;
}

.joinnow label {
  color: #041e37;
  font-size: 24px;
  font-weight: 600;
}

.joinnow .form-check-inline {
  margin-right: 0;
  width: 47%;
  position: relative;
  padding: 0;
}

.joinnow .flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.joinnow .form-check label {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0px 0px 17px -2px #e9d16f;
  border: 0;
  padding: 15px 20px;
  width: 100%;
  padding-left: 50%;
}
.joinnow .form-check label:hover {
  cursor: pointer;
  box-shadow: 0px 0px 17px -2px #b5ae90;
}
.joinnow .form-check input {
  color: #000;
  position: absolute;
  right: 0;
  left: -50px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  background-image: radial-gradient(#fff 32.93%, #fff, #cacaca 44%, white 50%);
  box-shadow: none !important;
}
.joinnow .form-check input:checked {
  background-image: radial-gradient(#d6b52e 44%, white 50%);
  border-color: #d6b52e;
}
.joinnow select {
  border-radius: 28px;
  box-shadow: 0px 0px 17px -2px #e9d16f !important;
  border: 0;
  padding: 14px 20px;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background-color: #fffae5;
}
.primary_modal .btn-close:focus {
  box-shadow: none;
}
.primary_modal .modal-header {
  border-bottom: 1px solid #f1ce40;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  border: 2px solid #f1ce40;
  background-color: transparent;
  color: #111111;
  border-radius: 15px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #000;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #000;
  font-size: 22px;
  font-weight: 400;
}
.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  filter: grayscale(1) hue-rotate(180deg);
}
.primary_modal a {
  text-decoration: none;
}
.plan_pool_third_row .plan_pool_head_child::after {
  display: none;
}
.custom_tooltip {
  background: #f8cd24 !important;
  color: #000 !important;
}
.custom_tooltip p {
  font-size: 18px;
}
.custom_tooltip::after {
  border-top-color: #f8cd24 !important;
}
.plan_wrapper p {
  font-size: 18px;
}

.community .inbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
}
.community .inbox p {
  margin-bottom: 10px;
}
.community .inbox h5 {
  margin-bottom: 0px;
}

.community .inbox.right_bdr {
  position: relative;
}
.community .inbox.right_bdr::before {
  content: "";
  position: absolute;
  right: -25%;
  height: 80%;
  width: 1px;
  background: #979595;
}
.community .input-group {
  max-width: 100% !important;
}
.community .user_flx {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.community .user_flx > div {
  display: flex;
  align-items: center;
}
.community .user_flx > div p {
  margin-bottom: 0;
}

.community .treeview {
  color: #000;
  overflow: visible !important;
  position: relative;
  padding-top: 30px !important;
}
.community .treeview i {
  font-size: 20px;
}
.community .treeview .id {
  color: #041e37;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.community .treeview .blur {
  opacity: 0.4;
}
.community .treeview div:not(.treeview) {
  padding-left: 30px !important;
  border-color: #979595 !important;
  border-left-style: solid !important;
}
.community .treeview .treeview::before {
  content: "";
  position: absolute;
  width: 30px;
  left: -30px;
  height: 1px;
  background: #979595;
  top: 43px;
}
/* hide last user line */
.community .tree .treeview::before {
  border: 0;
}
.community .treeview .treeview:nth-last-child(1)::before {
  content: "";
  position: absolute;
  width: 30px;
  left: -31px;
  height: 100%;
  background: #ffffff;
  top: 43px;
  border-top: 1px solid #979595;
}
/* .community .treeview div:not(.treeview)::before{
  content: "";
  background: #aa9292;
  height: calc(50% - 20px);
  width: 15px;
  display: block;
  left: -9px;
  position: absolute;
  bottom: 0;
} */
/* .community .treeview div:not(.treeview)::before {
  content: "";
  background: #9f7f7f;
  height: calc(50% - 20px);
  width: 1px;
  display: block;
  left: -1px;
  position: absolute;
  top: 0;
} */

/* Media Queries */
@media screen and (max-width: 1399px) {
  .custom_list {
    flex-wrap: wrap;
    gap: 30px;
  }

  .dashboard .account {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .page_header h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .main_title {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
  }
  .dashboard .box .flx {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard .box .flx span {
    width: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .primary_btn1 {
    color: #fff !important;
}
  .dropdown.me-3 {
    margin: 10px 0 10px !important ;
  }
  .navbar_right .navbar-nav li {
    margin-bottom: 10px;
  }
  .community .user_flx {
    flex-direction: column;
    width: fit-content;
    align-items: center;
    margin: 20px auto;
  }
  .community .user_flx > div {
    margin-bottom: 10px;
  }
  .community .box {
    flex-direction: column;
  }
  .community .inbox {
    width: 100%;
    margin-bottom: 15px;
  }
  .community .inbox:nth-last-child(1) {
    margin-bottom: 0;
  }
  .page_header {
    text-align: center;
  }

  .banner_btn_grp {
    justify-content: center;
  }

  .banner_info_box_section {
    margin-top: 0;
    padding: 80px 0 0;
  }

  .banner_info_box_single {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .banner_info_box_single > div {
    margin-left: 0;
    margin-top: -20px;
  }

  .community_section {
    text-align: center;
  }

  .community_section img {
    margin-top: 30px;
  }

  .plan_section {
    text-align: center;
  }

  .plan_detail_box {
    margin-top: 50px;
  }

  .navbar_right .nav-item + li {
    margin-left: 0;
  }
  .dashboard .box_bdr .box {
    flex-direction: column;
  }
  .dashboard .box_bdr .box p {
    margin-right: 0;
  }
  .dashboard_box {
    padding: 25px 20px;
    border-radius: 30px;
  }
  .dashboard .box {
    padding: 25px;
  }
  .dashboard .box_bdr {
    border-radius: 30px;
    padding: 20px;
  }
  .h2tag {
    font-size: 32px;
  }
  .dashboard .box_flx .input-group {
    max-width: 95%;
  }
  .dashboard .head_flx {
    flex-direction: column;
  }
  .dashboard .head_flx h2 {
    margin-bottom: 15px !important;
  }
  .dashboard .accordion-item {
    border-radius: 30px;
  }
  .dashboard .box_flx:nth-child(1) {
    margin-bottom: 0px;
  }
  .dashboard .box_flx {
    align-items: unset;
  }
  .dashboard .box_flx .box {
    width: 48%;
    padding: 15px;
    border-radius: 20px;
    display: flex;
  }
  .dashboard .blw_box {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .primary_btn1 {
    color: #fff !important;
}
  .h2tag {
    font-size: 28px;
  }
  .joinnow {
    background-size: cover;
    background-color: #04223d;
    background-position: top;
  }
  .joinnow .joinnow_box {
    padding: 20px;
  }
  .plan_pool {
    width: 600px;
  }
  .dashboard .box h5 {
    font-size: 24px;
  }
  .dashboard .box p {
    font-size: 16px;
  }
  .footer_panel {
    flex-wrap: wrap;
  }

  .footer_panel_left,
  .footer_panel_right {
    width: 100%;
    text-align: center;
  }

  .footer_social_links {
    justify-content: center;
    margin-top: 20px;
  }

  .activity_row {
    flex-wrap: wrap;
  }

  .activity_row_left,
  .activity_row_right {
    width: 100%;
  }

  .activity_row_right {
    justify-content: center;
    margin: 5px 0 10px;
  }

  .banner_info_box {
    flex-wrap: wrap;
  }

  .banner_info_box_single > div {
    margin-left: 10px;
    margin-top: 0;
  }

  .banner_info_box_single {
    flex-wrap: nowrap;
    justify-content: flex-start;
    text-align: left;
  }

  .page_header h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .main_title {
    font-size: 30px;
    line-height: 32px;
  }

  .banner_info_box_single > div h2,
  .plan_detail_box_top > div h2,
  .plan_detail_box_bottom h2 {
    font-size: 36px;
  }
  .dashboard .blw_box > div {
    flex-direction: column;
  }
  .dashboard .blw_box > div p {
    margin-bottom: 5px;
  }
  .dashboard .blw_box > div:nth-child(1) {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 575px) {
  .tree {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 10px;
  }
  .banner_btn_grp {
    gap: 10px;
  }
  .dashboard .accordion-button {
    padding: 15px 20px;
    font-size: 22px;
  }
  .dashboard .accordion-body {
    padding: 15px 20px;
  }
  .dashboard .accordion-button::after {
    width: 8px;
  }
  .dashboard .head_flx .wallet {
    font-size: 12px;
  }
  .dashboard .head_flx .wallet span {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .dashboard .box h5 {
    font-size: 22px;
  }
  .dashboard .box,
  .dashboard .box_bdr .box {
    padding: 20px;
  }
  .dashboard .box_flx .input-group {
    max-width: 100%;
  }
  .dashboard .box_flx {
    flex-direction: column;
  }
  .dashboard .box_flx .box {
    width: 100%;
    text-align: center;
  }
  .dashboard .box_flx .box div {
    width: 100%;
  }
  .activity_row_left {
    justify-content: center;
    flex-wrap: wrap;
  }

  .activity_row_right {
    margin: 15px 0 10px;
  }

  .primary_btn_arrow {
    display: none;
  }
}

img.blurr {
  filter: blur(2px);
  opacity: 0.8;
}

.Opacity {
  opacity: 0.1;
  pointer-events: none !important;
  overflow-y: hidden !important;
}
.blackText {
  position: absolute;
  top: 50%;
  left: 36%;
  right: 50%;
  width: 100%;
}
p.alert.alert-danger {
  padding: 10px 21px;
  border-radius: 50px;
  box-shadow: 0 0 10px 0 #fcf4d4;
  font-size: 13px;
  position: relative;
  top: 5px;
}
